:root {
  /* Colors */
  --intereast-red: #fa5a50;
  --intereast-mid-red: #fc9c96;
  --intereast-light-red: #fff3f0;
  --intereast-light-green: #d5e4e1;

  --intereast-dark-gray: #44474f;
  --intereast-gray: #f4f4f4;
  --intereast-light-gray: #fafafa;
  --intereast-tag-gray: #707070;
  --intereast-form-header: #1d1c1acc;
  --intereast-divider-gray: #9da1ab;
  --intereast-border-color: #d9d9d9;
  --description-color-text: rgba(0, 0, 0, 0.6);
  --placeholder-color-text: rgba(0, 0, 0, 0.45);
  --intereast-background-color: #eee;
  --intereast-background-color-light: rgba(203, 203, 203, 0.168);

  /* New Colors */
  --intereast-red: #fa5a50;
  --intereast-dark-red: #430b27;
  --intereast-purple: #8b5af7;
  --intereast-light-purple: #e7d5ea;
  --intereast-light-cream: #f6f3ee;
  --intereast-dark-cream: #f0eade;

  /* Typography */
  --low-emphasis: rgba(0, 0, 0, 0.45);
  --mid-emphasis: rgba(0, 0, 0, 0.65);
  --high-emphasis: rgba(0, 0, 0, 0.85);
  --warning: #ff9966;

  /* Status colors */
  --status-color-blue: #84b0f7;
  --status-color-green: #7eceab;
  --status-color-orange: #ffc247;
  --status-color-red: var(--intereast-red);
  --status-color-purple: #8772b4;

  /* Shadows */
  --card-shadow: 4px 4px 6px 0 #00000015;

  --card-shadow-inside-right: 4px 4px 6px 0 #00000015;
  --card-shadow-inside-left: -4px 4px 6px 0 #00000015;
  --card-shadow-bottom: 0px 4px 6px 0 #00000015;

  /* Special padding rules */
  --card-padding: 24px;
  --header-padding: 10px;

  /* Special margin rules */
  --card-margin: 10px;
  --header-button-margin: 16px;
  --header-card-margin: 20px;

  /* Spacing */
  --space-small: 4px; /* Use between icons and text */
  --space-base: 8px; /* Use between elements that are closely related (e.g. buttons on same line and elements in lists) */
  --space-large: 16px; /* Use between elements that are loosely related (e.g. container with comments, form that adds data to container) (usually used vertically) */
  --space-xl: 24px; /* Common spacing between unrelated elements vertically */
  --space-xxl: 32px; /* Use when spacing needs to be highlighted, but a divider adds too much noise */

  /* Input sizes */
  --input-extra-small: 72px;
  --input-small: 96px;
  --input-medium: 120px;
  --input-large: 178px;
  --input-extra-large: 256px;
  --input-extra-extra-large: 400px;

  /* Font families */
  --ant-default-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

html {
  --antd-wave-shadow-color: var(--intereast-red);
}
