.dropdown-component--no-margin {
  margin: 0 !important;
}

.dropdown-component__menu {
  max-height: 40vh;
  overflow-y: auto;
  outline: 1px solid #e0e0e0;
  border-radius: 4px;
}
