/**
 * Remove focus styles for non-keyboard focus.
 */
:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/* Global Styles */
@font-face {
  font-family: 'TT-Norms-Pro-Bold';
  src: local('TT-Norms-Pro-Bold'), url(assets/fonts/TT-Norms-Pro-Bold.otf);
}
@font-face {
  font-family: 'TT-Norms-Pro-Medium';
  src: local('TT-Norms-Pro-Medium'), url(assets/fonts/TT-Norms-Pro-Medium.otf);
}
@font-face {
  font-family: 'TT-Norms-Pro-Regular';
  src: local('TT-Norms-Pro-Regular'), url(assets/fonts/TT-Norms-Pro-Regular.otf);
}
@font-face {
  font-family: 'TT-Norms-Pro-Serif';
  src: local('TT-Norms-Pro-Serif'), url(assets/fonts/TT-Norms-Pro-Serif-Medium-Italic.otf);
}

html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  overflow: hidden;
  font-family: TTNorms, TT-Norms-Pro-Regular, sans-serif, 'HelveticaNeue-Light',
    'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
p {
  margin: 0;
}

.ant-table th {
  white-space: nowrap;
}
.ant-table td {
  white-space: nowrap;
}

.elise-drawer-actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* Print rules */
@media not print {
  .Print {
    display: none;
  }

  .no-print {
    display: block;
  }

  .Screen {
    display: block;
  }
}

@media print {
  .Screen {
    display: none;
  }

  .no-print {
    display: none;
  }

  .Print {
    display: block;
  }

  .Main .Header {
    display: none;
  }

  .Main .Sider {
    display: none;
  }

  .Main .Footer {
    display: none;
  }
}

.text--warning {
  color: var(--warning);
}
.text--error {
  color: var(--intereast-red);
}
.text--bold {
  font-weight: bold;
}
.text--heavy {
  font-weight: 800;
}
.text--light {
  font-weight: 300;
}

/* InterEast Fonts */
@font-face {
  font-family: 'TTNorms';
  src: url(./assets/fonts/38E4CB_0_0.eot);
  src:
    url(./assets/fonts/38E4CB_0_0.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/38E4CB_0_0.woff2) format('woff2'),
    url(./assets/fonts/38E4CB_0_0.woff) format('woff'),
    url(./assets/fonts/38E4CB_0_0.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TTNorms';
  src: url(./assets/fonts/38E4CB_1_0.eot);
  src:
    url(./assets/fonts/38E4CB_1_0.eot?#iefix) format('embedded-opentype'),
    url(./assets/fonts/38E4CB_1_0.woff2) format('woff2'),
    url(./assets/fonts/38E4CB_1_0.woff) format('woff'),
    url(./assets/fonts/38E4CB_1_0.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
