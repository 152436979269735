.error-boundary__container {
  min-height: 100vh;
  padding: 2rem;
}

.error-boundary__layout {
  display: flex;
  flex-direction: row;
  vertical-align: center;
  gap: 0.5rem;
}
