/* --- Dashboard card --- */
.card-heading {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: baseline;
}

.card-heading-label-text {
  line-height: 32px;
  text-transform: uppercase;
  font-family: 'TT-Norms-Pro-Regular';
}

.card-heading-label-divider {
  margin: 0 var(--space-small);
}

/* --- General view card --- */
.general {
  height: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.general__actions {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.general-card--no-padding {
  padding: 0 !important;
}

.view-card,
.details-card,
.form-card {
  margin: var(--card-margin);
  padding: var(--card-padding);
}

.view-card .ant-card-body,
.details-card .ant-card-body,
.form-card .ant-card-body {
  padding: 0;
}

.view-card-heading {
  margin: 0;
}
.view-card-heading.ant-page-header {
  padding: 0;
  padding-bottom: var(--space-base);
}

.general-view-card-actions {
  display: flex;
  justify-content: space-between;
}

.general-view-card-actions .left > * {
  margin: 0 var(--space-large) 0 0;
}

.general-view-card-actions .right > * {
  margin: 0 0 0 var(--space-large);
}

/* --- Empty Data --- */

.dashboard-card.empty-data .ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-small);
  height: calc(100% - 58px);
}

/* --- Item view card --- */
.item.view-card-heading.ant-page-header {
  padding-bottom: var(--space-xl);
}

.subTitleTag {
  display: flex;
  align-items: baseline;
}

.item-view-card-actions {
  display: flex;
  justify-content: space-between;
}

.item-view-card-actions .right > * {
  margin: 0 0 0 var(--space-large);
  margin-bottom: var(--space-xl);
}

/* --- Header view card --- */

.header.view-card-heading .ant-page-header-heading {
  height: 45px;
}

.header.view-card-heading.ant-page-header {
  padding-bottom: 0;
}

.header-card-breadcrumbs {
  margin-bottom: var(--space-large);
}

/* --- Details view card --- */

.details-cards-container {
  display: flex;
  flex-wrap: wrap;
}

.details-card-item {
  margin: 0.5rem;
  flex: 0 calc(50% - 1.5em);
  background: var(--intereast-light-gray);
  padding: 1rem;
  font-size: 14px;
  border-bottom: 1px solid #f0f0f0;
}

.ant-card .details-card-title {
  font-size: 18px;
}

.header-card-admin-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-large);
  margin-top: var(--space-base);
}

.header-card-admin-actions .left > * {
  margin: 0 var(--space-large) 0 0;
}

.header-card-admin-actions .right > * {
  margin: 0 0 0 var(--space-large);
}
