.card-component__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  overflow: hidden;
  width: 100%;
}

.card-component__icon {
  min-width: 68px;
}

.card-component__content {
  flex: 1 1 auto;
}
