.inter-typography-emphasis--high {
  color: var(--high-emphasis);
}
.inter-typography-emphasis--mid {
  color: var(--mid-emphasis);
}
.inter-typography-emphasis--low {
  color: var(--low-emphasis);
}
.inter-typography-weight--normal {
  font-weight: normal;
}
.inter-typography-weight--bold {
  font-weight: bold;
}
.inter-typography-size--large {
  font-size: 1.2rem;
}
.inter-typography-size--normal {
  font-size: 1rem;
}
.inter-typography-size--small {
  font-size: 0.8rem;
}
