.sider {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff !important;
  padding: 0;
  border-right: 1px solid var(--intereast-light-cream);
}

.sider__container {
  background-color: #fff !important;
  margin-bottom: 1.5rem;
}

.sider__expand-button {
  display: flex;
  position: sticky;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 1.5rem;
  cursor: pointer;
  width: 100%;
}

.sider__logo {
  display: flex;
  justify-content: center;
  margin-top: 14px;
  margin-bottom: 28px;
}

.sider__expand-text {
  display: flex;
  justify-content: center;
  font-size: 12px !important;
  margin-top: 0.5rem;
}

.sider__button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sider__nav-item {
  font-size: 16px !important;
  margin-right: 6px;
}
