.header__layout.ant-layout-header {
  height: 100%;
  padding: 0 16px;
  border-bottom: 1px solid var(--intereast-light-cream);
}

.header--background-white {
  background: white;
}

.header__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.header__logo-organization {
  max-height: 24px;
  max-width: 100%;
  height: 24px;
}

.header__env-banner {
  color: var(--intereast-red);
  font-size: 24px;
}

.header__profile-card:hover() {
  cursor: pointer;
}

.header__profile-card.ant-card:not(.ant-card-hoverable) {
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
