.inter-time-range-picker--container {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 1rem;
  max-width: calc(2 * var(--input-large));

}

.inter-time-range-picker--pickers {
  display: flex;
  flex-direction: row;
  gap: 1em;   
}

.inter-time-range-picker--vertical {
  flex-direction: column !important;
}
