/* 
Values taken from https://ant.design/components/grid#col
    xs: < 576px
    sm: >= 576px
    md: >= 768px
    lg: >= 992px
    xl: >= 1200px
    xxl: >= 1600px
*/
.modal__dimensions {
  top: 24px !important; /* Pushed the modal to the top of screen - 24px*/
  transition: ease all 0.5s;
}

.modal__dimensions .ant-modal-body {
  min-height: 15vh !important;
  /*  Two properties below enables vertical scrolling in the modal. */
  max-height: 75vh !important;
  overflow-y: auto !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .modal__container {
    width: 100% !important;
  }
  .modal__container--xsmall {
    width: 100vw !important;
  }
  .modal__container--small {
    width: 100vw !important;
  }
  .modal__container--large {
    width: 100vw !important;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .modal__container {
    width: 90% !important;
  }
  .modal__container--xsmall {
    width: 65vw !important;
  }
  .modal__container--small {
    width: 80vw !important;
  }
  .modal__container--large {
    width: 100vw !important;
  }
}
@media (min-width: 992px) {
  .modal__container {
    width: 70% !important;
  }
  .modal__container--xsmall {
    width: 40vw !important;
  }
  .modal__container--small {
    width: 50vw !important;
  }
  .modal__container--large {
    width: 80vw !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .modal__container {
    width: 60% !important;
  }
  .modal__container--xsmall {
    width: 25vw !important;
  }
  .modal__container--small {
    width: 40vw !important;
  }
  .modal__container--large {
    width: 75vw !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1600px) {
  .modal__container {
    width: 45% !important;
  }
  .modal__container--xsmall {
    width: 26vw !important;
  }
  .modal__container--small {
    width: 35vw !important;
  }
  .modal__container--large {
    width: 65vw !important;
  }
}

.modal__footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
