.link__container:focus {
  outline: 1px solid var(--intereast-red);
  outline-offset: 0.1rem;
}

.link__container {
  color: rgba(0, 0, 0, 0.64);
}

.link--underline {
  text-decoration: underline;
}
