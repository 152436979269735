.divider-component--margin {
  margin: 0.5rem 0 !important;
}

.divider-component--margin-bottom {
  margin-bottom: 0.5rem !important;
  margin-top: 0 !important;
}

.divider-component--vertical {
  height: 100%;
  margin: 0;
}
