.radio-group__container {
  display: flex;
  gap: var(--space-base);
  flex-wrap: wrap;
}

.radio-group__container label {
  border-radius: 4px !important;
  border: 1px solid var(--intereast-border-color) !important;
}

.radio-group__container label::before {
  content: none !important;
}

.radio-group__button:hover {
  color: var(--high-emphasis) !important;
  background: var(--intereast-background-color) !important;
}

.radio-group__button--selected {
  color: var(--high-emphasis) !important;
  background: var(--intereast-background-color) !important;
}
