.under-construction__hazard-border {
  padding: 1rem;
  border: 10px solid pink;
  border-image: repeating-linear-gradient(-55deg, #000, #000 20px, #ffb101 20px, #ffb101 40px) 10;
  animation: loading-slide 1s linear infinite;
}

@keyframes loading-slide {
  to {
    border-image: repeating-linear-gradient(
        -55deg,
        #000 20px,
        #000 40px,
        #ffb101 40px,
        #ffb101 60px
      )
      10;
  }
}

.under-construction__warning {
  width: 78%;
  animation: scroll 4s alternate infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(100%));
  }
}
