.header {
  grid-area: header;
  width: 100%;
}
.content {
  overflow: auto;
  grid-area: content;
  height: 100%;
  padding: 8px 0 0 8px;
}
.sidebar {
  height: 100%;
  overflow: auto;
  grid-area: sidebar;
  padding-bottom: 0.5rem;
}
.app-wrapper {
  display: grid;
  gap: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--intereast-gray);
  grid-template-columns: auto 1fr;
  grid-template-rows: 56px 1fr;
  grid-template-areas: 'sidebar header' 'sidebar  content';
}
.content--shadow--height {
  height: inherit;
}

@media print {
  #sider,
  #header,
  #content {
    display: none !important;
  } /* This will allow multiple pages when printing */
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }
  .app-wrapper {
    display: block;
    height: auto;
    overflow: visible;
    page-break-inside: auto;
  }
}
