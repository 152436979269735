.inter__input-field--xs {
  width: var(--input-extra-small) !important;
}
.inter__input-field--s {
  width: var(--input-small) !important;
}
.inter__input-field--m {
  width: var(--input-medium) !important;
}
.inter__input-field--l {
  width: var(--input-large) !important;
}
.inter__input-field--xl {
  width: var(--input-extra-large) !important;
}
.inter__input-field--xxl {
  width: var(--input-extra-extra-large) !important;
}
.inter__input-field--max {
  width: 100% !important;
}

.form-item--margin {
  margin: 0 !important;
}
