.content-button__container {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.content-button:hover {
  cursor: pointer;
}

.content-button:focus {
  color: var(--intereast-red) !important;
}
